<template>
  <n-data-table
    v-if="annotations"
    :columns="columns"
    :data="annotations"
    :bordered="false"
  />
</template>
<script setup>
import { h, ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import {
  NSpace,
  NButton,
  NInputGroup,
  NTag,
  NInput,
  NPopover,
  NDataTable,
  NImage,
} from 'naive-ui';

import { DateTime, Duration } from 'luxon';

const props = defineProps({
  annotations: {
    type: Array,
    default: null,
  },
  withSpectrogram: {
    type: Boolean,
    default: false,
  },
  withCreate: {
    type: Boolean,
    default: false,
  },
  withEdit: {
    type: Boolean,
    default: true,
  },
});

const router = useRouter();

const OFFSET_SECONDS = 10 * 60;

const emit = defineEmits([
  'onViewClicked',
  'onEditClicked',
  'onNewClicked',
  'onKeywordClicked',
]);

const editingAnnotation = ref(null);
const columns = ref([]);

onMounted(() => {
  columns.value = createColumns({
    onClickView(annotation) {
      emit('onViewClicked', annotation);
    },
    onClickEdit(annotation) {
      emit('onEditClicked', annotation);
    },
    onClickNew() {
      emit('onNewClicked');
    },
    onKeywordClicked(keyword) {
      emit('onKeywordClicked', keyword);
    },
  });
});

const onClickCopy = function (text) {
  if (window.isSecureContext) {
    navigator.clipboard.writeText(text);
  } else {
    console.warn('clipboard not working without https/localhost');
  }
};

const fmtDate = function (d) {
  const dt = DateTime.fromISO(d).toUTC();
  return dt.toFormat('LLL dd yyyy');
};
const fmtTime = function (d, seconds) {
  const dt = DateTime.fromISO(d).toUTC();
  const offset = Duration.fromObject({ seconds: seconds });
  return dt.plus(offset).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
};

const fmtDuration = function (seconds) {
  return Duration.fromObject({ seconds: seconds }).toFormat('mm:ss');
};

const title = function (onClickNew) {
  return [
    h('span', {
      innerHTML: 'Annotation',
    }),
    h('span', {
      innerHTML: '&nbsp;&nbsp;',
    }),
    props.withCreate === true
      ? h(
          NButton,
          {
            strong: true,
            tertiary: true,
            size: 'small',
            onClick: () => onClickNew(),
          },
          { default: () => 'New' }
        )
      : h('span', {}),
  ];
};

const showLink = function (annotation) {
  const location = router.resolve({
    name: 'play',
    query: {
      uuid: annotation.recording.uuid,
      signal: annotation.recording.signal,
      t: annotation.start,
    },
  });
  const absoluteURL = new URL(location.href, window.location.origin).href;
  let inputs = [
    h(NInput, {
      value: absoluteURL,
      focusable: false,
      autosize: true,
    }),
    h(
      NButton,
      {
        ghost: null,
        onClick: () => onClickCopy(absoluteURL),
      },
      { default: () => 'Copy' }
    ),
  ];
  return h(NInputGroup, {}, { default: () => inputs });
};

const renderKeywords = function (annotation, clicked) {
  let keywords = [];
  for (let keyword of annotation.keywords) {
    keywords.push(
      h(
        NTag,
        {
          bordered: false,
          type: 'info',
          size: 'small',
          style: { cursor: 'pointer' },
          onClick: () => clicked(keyword.slug),
        },
        { default: () => `#${keyword.name}` }
      )
    );
  }
  return h(NSpace, {}, { default: () => keywords });
};

const createColumns = ({
  onClickView,
  onClickEdit,
  onClickNew,
  onKeywordClicked,
}) => {
  const columns = [];

  if (props.withSpectrogram === true) {
    columns.push({
      title: 'Spectrogram',
      key: 'spectrogram',
      render(annotation) {
        return h(NImage, {
          src: annotation.recording.spectrogram,
          width: '250',
        });
      },
      width: 250,
    });
  }

  columns.push(
    {
      title: 'Date',
      key: 'created',
      render(annotation) {
        return h('div', {
          innerHTML: `${fmtDate(annotation.created)}`,
        });
      },
      width: 120,
      defaultSortOrder: 'descend',
      // sorter: (a, b) => a.created > b.created,
      sorter: 'default',
    },
    {
      title: title(onClickNew),
      key: 'content',
      render(annotation) {
        return h('div', {}, [
          h('i', {
            innerHTML: `${annotation.author}`,
          }),
          h('div', {
            innerHTML: `${annotation.content}`,
          }),
        ]);
      },
      width: 300,
    },
    {
      title: 'Signal',
      key: 'signal',
      render(annotation) {
        return h('div', {
          innerHTML: `${annotation.recording.signal}`,
          style: { 'text-transform': 'capitalize' },
        });
      },
      width: 120,
      sorter: (a, b) => a.recording.signal > b.recording.signal,
    },
    {
      title: 'Date',
      key: 'date',
      render(annotation) {
        return h('div', {
          innerHTML: `${fmtDate(annotation.recording.date)}`,
        });
      },
      width: 120,
      sorter: (a, b) => a.recording.date > b.recording.date,
    },
    {
      title: 'From',
      key: 'start',
      render(annotation) {
        return h('div', {
          innerHTML: `${fmtTime(annotation.recording.date, annotation.start)}`,
        });
      },
      width: 100,
    },
    {
      title: 'To',
      key: 'end',
      render(annotation) {
        return h('div', {
          innerHTML: `${fmtTime(annotation.recording.date, annotation.end)}`,
        });
      },
      width: 100,
    },
    {
      title: 'Duration',
      key: 'duration',
      render(annotation) {
        return h('div', {
          innerHTML: `${fmtDuration(annotation.end - annotation.start)}`,
        });
      },
      width: 100,
      sorter: (a, b) =>
        Duration.fromObject({ seconds: a.end - a.start }) -
        Duration.fromObject({ seconds: b.end - b.start }),
    },
    {
      title: 'Keywords',
      key: 'keywords',
      render(annotation) {
        return renderKeywords(annotation, onKeywordClicked);
      },
      width: 200,
    },
    {
      title: 'Action',
      key: 'actions',
      render(annotation) {
        return [
          h(
            NButton,
            {
              strong: true,
              tertiary: true,
              size: 'small',
              onClick: () => onClickView(annotation),
            },
            { default: () => 'Listen' }
          ),
          h('span', {
            innerHTML: '&nbsp;&nbsp;',
          }),
          annotation.editable && props.withEdit === true
            ? h(
                NButton,
                {
                  strong: true,
                  tertiary: true,
                  size: 'small',
                  onClick: () => onClickEdit(annotation),
                },
                { default: () => 'Edit' }
              )
            : h('span', {}),
          h('span', {
            innerHTML: '&nbsp;&nbsp;',
          }),
          h(
            NPopover,
            {
              trigger: 'click',
            },
            {
              trigger: () =>
                h(
                  NButton,
                  {
                    strong: true,
                    tertiary: true,
                    size: 'small',
                  },
                  { default: () => 'Share' }
                ),
              default: () => showLink(annotation),
            }
          ),
        ];
      },
      width: 140,
      fixed: 'right',
    }
  );

  if (window.innerWidth <= 1280) {
    console.log('width is smaller than 1000px');
    columns.splice(0, 2);
    columns.splice(1, 6);
  } else if (window.innerWidth <= 1800) {
    console.log('width is smaller than 1800px');
    columns.splice(4, 5);
  }
  return columns;
};
</script>
