const state = {
  mouseover: null,
};
const getters = {
  mouseover: (state, getters) => {
    return state.mouseover;
  },
};
const mutations = {
  mouseover(state, value) {
    state.mouseover = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  // actions,
  mutations,
};
