<template>
  <Loading v-if="isLoading" />
  <div v-else>
    <n-space v-if="recording" justify="center">
      <n-input-group>
        <n-tag :bordered="false" round>
          &nbsp;
          <router-link
            :to="{
              name: 'year',
              query: {
                location: location,
                signal: signal,
              },
            }"
            custom
            v-slot="{ navigate }"
          >
            <n-button
              size="small"
              icon-placement="right"
              text
              @click="navigate"
            >
              {{ recording.location }}
              <template #icon>
                <n-icon :component="ArrowLeft12Regular" />
              </template>
            </n-button>
          </router-link>
        </n-tag>
        <n-tag :bordered="false">
          <router-link
            v-if="recording"
            :to="{
              name: 'month',
              query: {
                year: recordingDate.year,
                month: recordingDate.month,
                location: location,
                signal: signal,
              },
            }"
            custom
            v-slot="{ navigate }"
          >
            <n-button
              size="small"
              icon-placement="right"
              text
              @click="navigate"
            >
              {{ recordingDate.monthName }} {{ recordingDate.year }}
              <template #icon>
                <n-icon :component="ArrowLeft12Regular" />
              </template>
            </n-button>
          </router-link>
        </n-tag>
        <n-select
          :value="signal"
          :options="signalOptions"
          @update:value="changeSignal"
          filterable
          size="small"
          :consistent-menu-width="false"
          style="text-transform: capitalize"
        />
        <n-tag :bordered="false" style="font-weight: bold">
          {{ recordingDate.dateAndTime }}
          &nbsp;
        </n-tag>
        <n-tag :bordered="false" round style="font-weight: bold">
          <Link v-if="audio" :href="audio.default_spectrogram" target="_blank">
            Spectrogram
          </Link>
          <template #icon>
            <n-icon :component="WindowNew20Regular" />
          </template>
        </n-tag>
      </n-input-group>
    </n-space>
    <nav class="inline"></nav>
    <n-grid v-if="recording" :x-gap="1" :cols="12">
      <n-gi :offset="2" span="8">
        <AnnotationDetailView />
        <AnnotationEditor
          v-if="showEditor"
          @on-save="onSaveAnnotation"
          @on-cancel="onCancelAnnotation"
          @on-delete="onDeleteAnnotation"
        />
      </n-gi>
    </n-grid>
    <!-- :key is needed so peaks.js is actually re-rendering, when audio is changed. this is hack is called "key-changing technique" -->
    <Peaks v-if="audio" :audio="audio" :key="audio.uuid" />
    <!-- transport control -->
    <n-grid class="controls" v-if="recording" :cols="3">
      <n-gi style="justify-self: start">
        <n-space justify="left" align="center" size="large">
          <div
            style="
              font-variant-numeric: tabular-nums;
              white-space: nowrap;
              text-transform: uppercase;
            "
          >
            {{ recordingDate.date }}
            {{ timeDisplay }}
          </div>
          <n-button circle tertiary type="primary" size="medium">
            <template #icon>
              <n-icon @click.prevent="downloadAudio()">
                <arrow-download-20-regular />
              </n-icon>
            </template>
          </n-button>
          <n-button
            @click.exact.prevent="toggleAnnotations()"
            tertiary
            type="primary"
            size="medium"
          >
            <template #icon>
              <n-icon>
                <comment-20-regular />
              </n-icon>
            </template>
            Annotations
          </n-button>
        </n-space>
      </n-gi>
      <n-gi>
        <n-space justify="center" align="center" size="large">
          <router-link
            :to="{
              name: 'play',
              query: { uuid: recording.previous_uuid, signal: signal },
            }"
          >
            <n-button
              @click="onNextPreviousClick"
              circle
              tertiary
              type="primary"
              size="medium"
            >
              <template #icon>
                <n-icon
                  :disabled="!recording.previous_uuid"
                  :class="{ disabled: !recording.previous_uuid }"
                >
                  <arrow-previous-20-regular />
                </n-icon>
              </template>
            </n-button>
          </router-link>
          <n-button
            circle
            tertiary
            type="primary"
            size="large"
            @click="togglePlayPause"
          >
            <template #icon>
              <n-icon :component="playerControlsIcon" />
            </template>
          </n-button>
          <router-link
            :to="{
              name: 'play',
              query: { uuid: recording.next_uuid, signal: signal },
            }"
            style="color: white"
          >
            <n-button
              @click="onNextPreviousClick"
              circle
              tertiary
              type="primary"
              size="medium"
            >
              <template #icon>
                <n-icon
                  :disabled="!recording.next_uuid"
                  :class="{ disabled: !recording.next_uuid }"
                >
                  <arrow-next-20-regular />
                </n-icon>
              </template>
            </n-button>
          </router-link>
        </n-space>
      </n-gi>
      <n-gi style="justify-self: end">
        <n-button-group vertical>
          <n-button circle tertiary type="primary" size="medium">
            <template #icon>
              <n-icon
                v-on:click.exact.prevent="zoom(30)"
                v-on:click.shift.prevent="zoom(300)"
              >
                <zoom-in-20-regular />
              </n-icon>
            </template>
          </n-button>
          <n-button circle tertiary type="primary" size="medium">
            <template #icon>
              <n-icon
                v-on:click.exact.prevent="zoom(-30)"
                v-on:click.shift.prevent="zoom(-300)"
              >
                <zoom-out-20-regular />
              </n-icon>
            </template>
          </n-button>
        </n-button-group>
      </n-gi>
    </n-grid>
    <AnnotationListView
      v-if="showAnnotations"
      @on-view-clicked="onAnnotationViewClicked"
      @on-edit-clicked="onAnnotationEditClicked"
      @on-new-clicked="onAnnotationNewClicked"
      @on-keyword-clicked="onAnnotationKeywordClicked"
      :annotations="annotations"
      :withCreate="isLoggedIn"
    />
  </div>
</template>

<script setup>
import axios from 'axios';
import { ref, reactive, computed, onMounted, watch } from 'vue';
import { onBeforeRouteUpdate, useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

import {
  NEl,
  NAffix,
  NIcon,
  NSpace,
  NInputGroup,
  NButton,
  NButtonGroup,
  NSelect,
  NTag,
  NGrid,
  NGi,
} from 'naive-ui';
import {
  ArrowNext20Regular,
  ArrowPrevious20Regular,
  Play20Regular,
  Pause20Regular,
  ArrowDownload20Regular,
  ZoomIn20Regular,
  ZoomOut20Regular,
  ArrowLeft12Regular,
  Edit16Regular,
  Comment20Regular,
  WindowNew20Regular,
} from '@vicons/fluent';

import { DateTime, Duration } from 'luxon';

import Peaks from '@/components/Peaks.vue';
import Link from '@/components/Link.vue';
import Loading from '@/views/Loading.vue';
import AnnotationDetailView from '@/components/Annotation.vue';
import AnnotationEditor from '@/components/AnnotationEditor.vue';
import AnnotationListView from '@/views/AnnotationListView.vue';
import { Recording, Annotation } from '@/api/archive';

const debug = process.env.NODE_ENV !== 'production';

const props = defineProps({
  uuid: {
    type: String,
    default: null,
  },
  signal: {
    type: String,
    default: null,
  },
  t: {
    type: Number,
    default: 0,
  },
});

const router = useRouter();
const store = useStore();
const recording = ref(null);
const audio = ref(null);
const annotations = computed(() => store.getters['annotation/annotations']);

const loading = ref(false);

const showAnnotations = ref(false);
const showEditor = computed(() => {
  return store.getters['annotation/editor'] !== null;
});

const query = reactive({
  uuid: props.uuid,
  signal: props.signal,
  t: props.t,
});

watch(() => props.uuid, initialize);
watch(() => props.signal, initialize);

store.commit('player/setOffsetSeconds', props.t);

const offsetSeconds = computed(() => {
  return store.getters['player/offsetSeconds'];
});
watch(offsetSeconds, (seconds, old) => {
  if (seconds !== old) {
    router.replace({
      query: {
        uuid: props.uuid,
        signal: props.signal,
        t: seconds,
      },
    });
  }
});

// functions
async function fetchAnnotations() {
  if (audio.value === null) {
    return null;
  }
  await store.dispatch('annotation/fetchAnnotations', audio.value.uuid);
}

async function initialize() {
  try {
    // reset annotation store
    store.commit('annotation/resetEditor');
    store.commit('annotation/resetSegments');

    // fetch recording
    recording.value = await Recording.detail(props.uuid);

    // assign audio based on signal
    for (const obj of recording.value.audio) {
      if (obj.signal.value == props.signal) {
        audio.value = obj;
        break;
      }
    }

    audio.value = recording.value.audio.filter(
      (a) => a.signal.value == props.signal
    )[0];

    await fetchAnnotations();

    // show annotations by default only if there is something to show..
    showAnnotations.value = annotations.value.length != 0;
  } catch (error) {
    console.log(error);
    if (error.response.status === 404) {
      await router.replace({ name: 'NotFound' });
    }
  }
}

onMounted(async () => {
  await initialize();
});

function updateRoute() {
  router.push({ query: query });
}

function changeSignal(value, option) {
  query.signal = value;
  updateRoute();
}

function toggleAnnotations() {
  showAnnotations.value = !showAnnotations.value;
}

function onAnnotationViewClicked(annotation) {
  store.commit('player/doSeek', annotation.start);
}

function onAnnotationEditClicked(annotation) {
  store.commit('player/doSeek', annotation.start);
  store.commit('annotation/setEditor', {
    uuid: annotation.uuid,
    audio: audio.value.url,
    content: annotation.content,
    categories: annotation.categories,
    keywords: annotation.keywords,
    start: annotation.start,
    end: annotation.end,
  });
}

function onAnnotationNewClicked() {
  store.commit('annotation/setEditor', {
    uuid: 'NEW_ITEM',
    audio: audio.value.url,
    start: store.getters['player/offsetSeconds'],
    end: store.getters['player/offsetSeconds'] + 1,
  });
}

function onAnnotationKeywordClicked(keyword) {
  router.push({
    name: 'annotation',
    query: {
      keyword: keyword,
    },
  });
}

async function onSaveAnnotation() {
  await fetchAnnotations();
  store.commit('annotation/resetEditor');
}

async function onDeleteAnnotation() {
  await fetchAnnotations();
  store.commit('annotation/resetEditor');
}

function onCancelAnnotation() {
  store.commit('annotation/resetEditor');
}

function downloadAudio() {
  loading.value = true;
  const fileUrl = audio.value.file;
  axios({
    method: 'get',
    url: fileUrl,
    responseType: 'arraybuffer',
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      const fileName = fileUrl.split('/').pop();
      link.setAttribute('download', fileName); //or any other extension
      document.body.appendChild(link);
      link.click();
      loading.value = false;
    })
    .catch(() => {
      loading.value = false;
      console.log('error occured');
    });
}

function togglePlayPause() {
  store.dispatch('player/playPause');
}

function zoom(value) {
  store.commit('player/zoom', value);
}

function onNextPreviousClick() {
  store.commit('player/setOffsetSeconds', 0);
}

// computed
const recordingDate = computed(() => {
  if (recording.value === null) {
    return null;
  }
  const dt = DateTime.fromISO(recording.value.date).toUTC();
  return {
    dt: dt,
    year: dt.year,
    month: dt.month,
    monthName: dt.toFormat('MMMM'),
    day: dt.day,
    date: dt.toFormat('LLL dd yyyy'),
    dateAndTime: dt.toFormat('LLL dd yyyy T'),
  };
});

const timeDisplay = computed(() => {
  if (recordingDate.value === null) {
    return;
  }
  const seconds = store.getters['player/offsetSeconds'] || 0;
  const offset = Duration.fromObject({ seconds: seconds });
  return recordingDate.value.dt
    .plus(offset)
    .toLocaleString(DateTime.TIME_24_WITH_SECONDS);
});

const canEdit = computed(() => {
  return true;
});

const isLoading = computed(() => {
  return loading.value === true;
});

const playerControlsIcon = computed(() => {
  return store.getters['player/isPlaying'] ? Pause20Regular : Play20Regular;
});

const location = computed(() => {
  if (recording.value !== null) {
    return 'Heligoland' === recording.value.location ? 'HEL' : 'SPI';
  }
});

const signalOptions = computed(() => {
  const options = recording.value.audio.map((a) => {
    return {
      label: a.signal.name,
      value: a.signal.value,
    };
  });
  return options;
});

const isLoggedIn = computed(() => {
  return store.getters['auth/authenticated'];
});
</script>

<style lang="scss" scoped>
@import '@/style/_variables.scss';
.controls {
  margin: 50px 10px 50px 10px;
}
</style>

<!--// window.addEventListener('keydown', (e) => {-->
<!--//-->
<!--//   if (!this.isEditing) {-->
<!--//     let map = {};-->
<!--//     if (e.shiftKey) {-->
<!--//       map = {-->
<!--//         'ArrowLeft': {method: 'audioState', args: ['seekBackward', {backward: 2}]}, // left-->
<!--//         'ArrowRight': {method: 'audioState', args: ['seekForward', {forward: 2}]}, // right-->
<!--//       }-->
<!--//     } else {-->
<!--//       map = {-->
<!--//         ' ': {method: 'audioState', args: ['playPause']}, // space-->
<!--//         'ArrowLeft': {method: 'audioState', args: ['seekBackward', {backward: 0.1}]}, // left-->
<!--//         'ArrowRight': {method: 'audioState', args: ['seekForward', {forward: 0.1}]}, // right-->
<!--//         'Home': {method: 'audioState', args: ['seek', {time: 0.0}]}, // home-->
<!--//         'End': {-->
<!--//           method: 'audioState',-->
<!--//           args: ['seek', {time: this.peaks.player.getDuration() - 0.001}]-->
<!--//         }, // end-->
<!--//       };-->
<!--//     }-->
<!--//     const m = map[e.key];-->
<!--//     if (m !== undefined) {-->
<!--//       e.preventDefault();-->
<!--//       this[m.method](...m.args);-->
<!--//     }-->
<!--//   }-->
<!--// });-->
