import { client, handleError } from './client';

const debug = process.env.NODE_ENV !== 'production';

const Category = {
  async get(url, params) {
    try {
      const request = await client.get(`${url}`, {
        params: params,
      });
      return request.data;
    } catch (error) {
      handleError(error);
    }
  },
  async filter(params) {
    try {
      const request = await client.get('classification/category/', {
        params: params,
      });
      return request.data.results; // ignore pagination for now (only return first page)
    } catch (error) {
      handleError(error);
    }
  },
  async all() {
    return this.filter({});
  },
};

const Keyword = {
  async get(url, params) {
    try {
      const request = await client.get(`${url}`, {
        params: params,
      });
      return request.data;
    } catch (error) {
      handleError(error);
    }
  },
  async filter(params) {
    try {
      const request = await client.get('classification/keyword/', {
        params: params,
      });
      return request.data.results; // ignore pagination for now (only return first page)
    } catch (error) {
      handleError(error);
    }
  },
  async all() {
    return this.filter({});
  },
};

const Author = {
  async get(url, params) {
    try {
      const request = await client.get(`${url}`, {
        params: params,
      });
      return request.data;
    } catch (error) {
      handleError(error);
    }
  },
  async filter(params) {
    try {
      const request = await client.get('classification/author/', {
        params: params,
      });
      return request.data.results; // ignore pagination for now (only return first page)
    } catch (error) {
      handleError(error);
    }
  },
  async all() {
    return this.filter({});
  },
};

const Signal = {
  async filter(params) {
    try {
      const request = await client.get('classification/signal/', {
        params: params,
      });
      return request.data;
    } catch (error) {
      handleError(error);
    }
  },
  async all() {
    return this.filter({});
  },
};

export { Category, Keyword, Author, Signal };
