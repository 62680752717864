import { createStore, createLogger } from 'vuex';
import auth from './modules/auth';
import player from './modules/player';
import annotation from './modules/annotation';
import stereometry from './modules/stereometry';
import monthview from './modules/monthview';

const debug = process.env.NODE_ENV !== 'production';

export default createStore({
  modules: {
    auth,
    player,
    annotation,
    stereometry,
    monthview,
  },
  mutations: {
    initialiseStore(state) {
      // Replace the state object with the stored item
      if (localStorage.getItem('store')) {
        if (debug) {
          console.log('initialize store');
        }
        const cached = JSON.parse(localStorage.getItem('store'));
        this.replaceState(Object.assign(state, cached));
        if (debug) {
          console.log('initialized store', this.state);
        }
      }
    },
  },
  strict: debug,
  // plugins: debug ? [createLogger()] : []
});
