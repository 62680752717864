<template>
  <div v-if="recording" class="wrapper">
    <router-link
      :to="{
        name: 'play',
        query: {
          uuid: recording.uuid,
          signal: signal,
        },
      }"
    >
      <div class="item"></div>
      <div
        style="
          display: inline-block;
          color: white;
          position: relative;
          border: 1px solid transparent;
          top: -20px;
          left: -25px;
        "
        v-if="props.hasAnnotation && mouseOverGrid"
      >
        >
      </div>
    </router-link>
  </div>
  <div v-else class="item"></div>
</template>

<script setup>
import { computed } from 'vue';

import { useStore } from 'vuex';

const props = defineProps({
  item: Object,
  hasAnnotation: Boolean,
  signal: String,
});

const store = useStore();

const recording = computed(() => {
  return props.item.recording;
});

const mouseOverGrid = computed(() => {
  return store.getters['monthview/mouseover'];
});
</script>

<style lang="scss" scoped>
.item {
  max-width: 100%;
  height: 100%;
}
.wrapper:hover {
  border: 1px solid rgb(187, 255, 187);
}
</style>
