<template>
  <Loading v-if="isLoading" />
  <div v-else>
    <n-space justify="center">
      <n-input-group>
        <n-tag :bordered="false" round>
          &nbsp;
          <router-link
            :to="{
              name: 'year',
              query: {
                location: query.location,
                signal: query.signal,
              },
            }"
            custom
            v-slot="{ navigate }"
          >
            <n-button
              size="small"
              icon-placement="right"
              text
              @click="navigate"
            >
              {{ displaySignal }} | {{ displayLocation }}
              <template #icon>
                <n-icon :component="ArrowLeft12Regular" />
              </template>
            </n-button>
          </router-link>
        </n-tag>
        <n-select
          :value="month"
          :options="monthOptions"
          @update:value="changeMonth"
          filterable
          size="small"
          :consistent-menu-width="false"
        />
        <n-select
          :value="year"
          :options="yearOptions"
          @update:value="changeYear"
          filterable
          size="small"
          :consistent-menu-width="false"
        />
        <n-tag :bordered="false" round>&nbsp;&nbsp;</n-tag>
      </n-input-group>
      <div
        v-if="dates"
        :style="spectrogramStyle"
        class="spectrogram"
        @mouseenter="onMouseOver(true)"
        @mouseleave="onMouseOver(false)"
      >
        <div :style="cellStyle">dd\hh</div>
        <div
          :style="cellStyle"
          v-for="h in [...Array(24).keys()]"
          :key="h"
          class="hour"
        >
          {{ hourDisplay(h) }}
        </div>
        <div class="days" :style="{ 'grid-row-end': daysInMonth + 2 }">
          <template v-for="d in [...Array(daysInMonth).keys()]" :key="d">
            <router-link
              :to="{
                name: 'day',
                query: {
                  location: query.location,
                  signal: query.signal,
                  year: query.year,
                  month: query.month,
                  day: d + 1,
                },
              }"
              custom
              v-slot="{ href, navigate }"
            >
              <a :style="cellStyle" :href="href">
                {{ dayDisplay(d) }}
              </a>
            </router-link>
          </template>
        </div>
        <MonthItemView
          :item="item"
          :signal="query.signal"
          :hasAnnotation="hasAnnotation(item)"
          v-for="(item, index) in datesMap"
          :key="index"
          :style="cellStyle"
          :mouseOverGrid="mouseOverGrid"
        />
      </div>
      <div v-else>No data available.</div>
    </n-space>
  </div>
</template>

<script setup>
import { computed, onMounted, onUnmounted, ref, reactive, watch } from 'vue';

import { useRoute, useRouter } from 'vue-router';
import { DateTime, Info } from 'luxon';

import { useStore } from 'vuex';

import {
  NEl,
  NSpace,
  NIcon,
  NInputGroup,
  NButton,
  NTag,
  NGrid,
  NGi,
  NSelect,
  useThemeVars,
} from 'naive-ui';

import { Recording } from '@/api/archive';

import {
  ArrowForward16Filled,
  ArrowFit16Regular,
  ArrowLeft12Regular,
} from '@vicons/fluent';

import Loading from '@/views/Loading.vue';
import MonthItemView from '@/views/MonthItemView.vue';

const debug = process.env.NODE_ENV !== 'production';

const props = defineProps({
  year: {
    type: Number,
    default: null,
  },
  month: {
    type: Number,
    default: null,
  },
  location: {
    type: String,
    default: null,
  },
  signal: {
    type: String,
    default: null,
  },
});

const route = useRoute();
const router = useRouter();
const theme = useThemeVars();
const store = useStore();

const loading = ref(false);
const dates = ref([]);
const datesMap = ref(null);
const signals = ref([]);
const spectrogram = ref({});
const recordings = ref([]);
const annotations = ref([]);
const query = reactive({
  location: props.location,
  signal: props.signal,
  year: props.year,
  month: props.month,
});

const width = ref(null);

const mouseOverGrid = ref(false);

watch(
  () => props.year,
  async () => {
    if (debug) {
      console.log('year changed to:', props.year);
    }
    await fetch();
  }
);

watch(
  () => props.month,
  async () => {
    if (debug) {
      console.log('month changed to:', props.month);
    }
    await fetch();
  }
);

onMounted(async () => {
  if (debug) {
    console.log('MontView.onMounted');
  }

  width.value = document.documentElement.clientWidth;
  window.addEventListener('resize', onResize);

  await fetchDates();
  await fetch();
});

onUnmounted(() => {
  window.removeEventListener('resize', onResize);
});

async function fetch() {
  loading.value = true;
  // fetch recordings
  if (debug) {
    console.log('fetch data with query:', query);
  }
  recordings.value = await Recording.filter({
    year: query.year,
    month: query.month,
    location: query.location,
    signal: query.signal,
  });

  annotations.value = await Recording.annotation({
    year: query.year,
    month: query.month,
    location: query.location,
    signal: query.signal,
  });

  // map recordings to [day][hour] matrix
  let dates = [...Array(daysInMonth.value)].map((D, d) =>
    [...Array(24)].map((H, h) => {
      return { day: d + 1, hour: h, recording: null };
    })
  );
  recordings.value.forEach((recording) => {
    const rec_date = DateTime.fromISO(recording.date).toUTC();
    dates[rec_date.day - 1][rec_date.hour].recording = recording;
  });
  datesMap.value = dates.flat();

  // fetch spectrogram
  if (debug) {
    console.log('fetch spectrogram with query:', query);
  }
  spectrogram.value = await Recording.spectrogram({
    year: query.year,
    month: query.month,
    location: query.location,
    signal: query.signal,
  });
  if (debug) {
    console.log('fetched spectrogram:', spectrogram.value);
  }

  loading.value = false;
}

async function fetchDates() {
  loading.value = true;
  if (debug) {
    console.log('fetch signals');
  }
  signals.value = await Recording.signals({
    location: query.location,
    year: query.year,
    month: query.month,
  });
  if (debug) {
    console.log('fetch dates with query:', query);
  }
  dates.value = await Recording.dates({
    location: query.location,
    signal: query.signal,
  });
  loading.value = false;
}

function hasAnnotation(item) {
  const filtered = annotations.value.filter((a) => {
    if (item.recording !== null) {
      return a.uuid == item.recording.uuid;
    }
  });
  if (filtered.length > 0) {
    return true;
  }
  return false;
}

function onResize(event) {
  width.value = document.documentElement.clientWidth;
}

const isLoading = computed(() => loading.value === true);
const daysInMonth = computed(
  () => DateTime.utc(parseInt(query.year), parseInt(query.month), 1).daysInMonth
);
const availableYears = computed(() => dates.value.map((obj) => obj.year));

const availableMonthsForYear = (year) => {
  if (dates.value === null) {
    return null;
  }
  const months =
    (dates.value.find((date) => date.year === Number(year)) || {})['months'] ||
    null;
  if (months !== null) {
    return months.map((m) => m.month);
  }
  return null;
};

function updateRoute() {
  router.push({ query: query });
}

function changeYear(value, option) {
  const year = parseInt(value);
  const months = availableMonthsForYear(year);
  query.year = year;
  query.month = months[0];
  updateRoute();
}

function changeMonth(value, option) {
  query.month = parseInt(value);
  updateRoute();
}

const locationOptions = computed(() => {
  const options = [
    {
      value: 'HEL',
      label: 'Heligoland',
    },
    {
      value: 'SPI',
      label: 'Spitzbergen',
    },
  ];
  return options;
});

const displayLocation = computed(() => {
  let location = locationOptions.value.filter((opt) => {
    return opt.value == query.location;
  });
  return location[0].label;
});

const displaySignal = computed(() => {
  if (signals.value) {
    let signal = signals.value.filter((s) => {
      return s.value == query.signal;
    });
    if (signal.length) {
      const s = signal[0];
      return s.name;
    }
  }
});

const yearOptions = computed(() => {
  const options = availableYears.value.map((y) => {
    return {
      label: y,
      value: y,
    };
  });
  return options;
});

const monthDisplay = (month) => {
  return Info.months()[Number(month)];
};

const monthOptions = computed(() => {
  const months = availableMonthsForYear(query.year);
  if (months == null) {
    return [];
  }
  return months.map((m) => {
    return {
      label: monthDisplay(m - 1),
      value: m,
    };
  });
});

const dayDisplay = computed(() => {
  return (day) => {
    return String(day + 1).padStart(2, '0');
  };
});
const hourDisplay = computed(() => {
  return (hour) => {
    return String(hour).padStart(2, '0');
  };
});
const cellHeight = computed(() => {
  return 19 * 0.9;
});
const cellStyle = computed(() => {
  return {
    height: `${cellHeight.value}px`,
    color: theme.value.primaryColor,
  };
});

const spectrogramStyle = computed(() => {
  //const w = 70 * 0.9; // fixed tile_size width of month spectrogram
  const w = (width.value / 30) * 0.9; // fixed tile_size width of month spectrogram
  const h = cellHeight.value;
  return {
    'grid-template-columns': `repeat(25, ${w}px)`,
    'background-image': `url("${spectrogram.value.url}")`,
    'background-size': `calc(24 * ${w}px) calc(${daysInMonth.value} * ${h}px)`,
    'background-position': `${w}px ${h}px`,
    'background-repeat': 'no-repeat',
  };
});

function onMouseOver(value) {
  store.commit('monthview/mouseover', value);
}
</script>

<style lang="scss" scoped>
.spectrogram {
  margin-top: 30px;
  display: grid;
  text-align: center;
  .days {
    display: grid;
    grid-row-start: 2;
    justify-items: center;
    align-items: center;
  }
}
</style>
